
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

:root {
  --body-color: #f9f9f9;
  --header-bg-color: #262626;
  --header-text-color: #f6f7f9;
  --header-hover-color: #ed5434;
  --title-color: #ed5434;
}

body {
  background: var(--body-color);
}

a {
  &:hover {
    text-decoration: none;
  }
}

.filters {
  background: #FFF;
  padding: 15px;
  box-shadow: 0 0 5px 0 RGB(0 0 0 / 10%);

  h4 {
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      margin: 5px 0;
      font-size: 0.90em;

      > div {
        flex-grow: 1;
      }
    }
  }
}

.template-list {
  margin-top: 30px;
}

header {
  background: var(--header-bg-color);
  height: 60px;
  display: flex;
  align-items: center;
  color: var(--header-text-color);

  > .container {
    display: flex;
    align-items: center;

    > div {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .logo {
      height: 30px;
    }
  }

  ul {
    margin: 0 10px 0 0;
    padding: 0;
    list-style: none;
    display: flex;

    > li {
      > a {
        display: block;
        margin: 0 10px;
        color: var(--header-text-color);
        font-size: 0.95em;

        &:hover {
          color: var(--header-hover-color);
        }
      }
    }
  }

  .header-btn {
    background: var(--header-text-color);
    padding: 5px 15px;
    margin: 0 5px;
    border-radius: 15px;
    color: var(--header-hover-color);
    font-weight: 500;
    font-size: .85em;
  }
}

.single-template {
  display: block;
  background: #FFF;
  box-shadow: 0 0 5px 0 RGB(0 0 0 / 10%);

  &, &:hover {
    color: #282c34;
    text-decoration: none;
  }

  div.small-info {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;

    span:not(:first-child):before {
      content: ', ';
      margin: 0 0 5px 0;
    }
  }

  & div.info {
    display: flex;
    align-items: center;
    padding: 10px 10px;

    h3 {
      flex-grow: 1;
      font-size: 0.90em;
      font-weight: 500;
      color: var(--title-color);
      margin:0;
    }

    h4 {
      font-size: 0.75em;
      margin: 0;
    }
  }
}

footer {
  background: var(--header-bg-color);
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--header-text-color);

  a {
    color: var(--header-text-color);

    &:hover {
      color: var(--header-text-color);
    }
  }
}


$color-active: #FD723D;
$color-disabled: #C0C0C0;

ul.modules {
  margin: 15px 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;

  > li {
    flex-grow: 1;
    text-align: center;

    &:first-child {
      > span::after {
        left: 50%;
      }

      &.active {
        > span::after {
          background: $color-disabled;
        }
      }
    }

    &:last-child {
      > span::after {
        right: 50%;
      }

      &.active {
        > span::after {
          background: $color-active;
        }
      }
    }

    &.past {
      color: #000;

      > span {
        &::before {
        }

      }
    }

    &:not(.past) {
      color: #C0C0C0;
    }

    &.active {
      > span::before {
        background: $color-active;
      }

      > span::after {
        background: linear-gradient(90deg,$color-active 50%, $color-disabled 0);
      }
    }

    &.future {
      > span::before {
        background: #E1DDD9;
        border-color:  #E1DDD9;
      }
    }

    &.past {
      > span::before {
        background: #FFF url("https://icons.getbootstrap.com/assets/icons/check2.svg") no-repeat center;
        font-weight: bold;
      }

      > span::after {
        background: $color-active;
      }
    }

    > span {
      display: block;
      position: relative;

      &::before {
        position: relative;
        display: block;
        content: ' ';
        height: 32px;
        width: 32px;
        margin: 0 auto;
        border: solid 4px $color-active;
        border-radius: 50%;
        z-index: 2;
      }

      &::after {
        content: ' ';
        display: block;
        width: 100%;
        height: 4px;
        top: 14px;
        z-index: 1;
        position: absolute;
        background: #C0C0C0;
      }
    }
  }
}

.field-required {
  font-size: .5em;
  vertical-align: top;
}

[style*="--aspect-ratio"]::before {
  max-height: 70vh;
}
