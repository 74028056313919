
@import '~bootstrap/dist/css/bootstrap.min.css';

#root {
  min-height: 100vh;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main > div.content {
  flex-grow: 1;
}

